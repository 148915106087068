import React from 'react'
import ContactForm from '../components/ContactForm'
import GoogleMap from '../components/GoogleMap'
//import transition from '../transition'

const ContactUs = () => {
  return (
    <div className='h-screen items-center grid'>
       <div className="container">
          <h1 className="my-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Contact Us</h1>
          <div className=" grid lg:grid-cols-2 gap-4">
            <ContactForm />
            <GoogleMap />
          </div>
      </div>
    </div>
  )
}

export default ContactUs