import React from 'react'

const Footer = () => {
  return (
    <div className="py-2 ">
      <div className="container">
          <div className="md:flex text-center justify-between">
            <span>
            © 2018-2023 <strong className="text-maincolor">Bratyak Academy</strong> | All Rights Reserved
            </span>
            <p>Developed by: Bratyak Academy</p>
          </div>
      </div>
    </div>
  )
}

export default Footer
