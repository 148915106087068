import React from 'react'

const Button = ({ onClick, text, className }) => {
  return (
    <button
        className={`bg-maincolor w-fit hover:shadow-lg duration-500 ease-in-out text-white font-thin py-2 px-4 rounded ${className}`}
        onClick={onClick}
    >
    {text}
  </button>
  )
}

export default Button
