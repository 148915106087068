import React, { useState } from 'react'
import Logo from '../Logo'
import { Link, NavLink } from 'react-router-dom'
import {HiMenuAlt3} from 'react-icons/hi'
import {FaTimes} from 'react-icons/fa'
import {motion} from 'framer-motion'

const menuList = [
  {
    link: '/',
    Menu: 'Home'
  },
  {
    link: '/courese',
    Menu: 'Courese'
  },
  {
    link: '/contact-us',
    Menu: 'Contact Us'
  },
  {
    link: '/about-us',
    Menu: 'About Us'
  }
]

const Navbar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  function menuHandler(){
    setIsOpenMenu(!isOpenMenu);

    if (!isOpenMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }
  return (
    <div className="py-3">
      <div className="container flex gap-3 items-center justify-between">
       <Link to='/' >
        <motion.div
          initial={{y: -100, opacity: 0}}
          animate={{y: 0, opacity: 1 }}
          transition={{delay: 0.5}}
          className="max-w-[120px]"
          src={Logo} 
          alt="company logo" >
            <Logo />
          </motion.div>
       </Link>
        <nav className="hidden md:block">
          <ul
            className="flex  text-base py-2 uppercase relative"
          >
            {menuList.map((menu, value) => (
              <motion.li
                initial={{y: -50, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{delay: value * 0.1, duration: 0.2 * value}}
                className="p-2 cursor-pointer"
                key={value} >
                <NavLink
                  className="text-gray-600 hover:text-maincolor duration-300"
                  to={menu.link}
                >{menu.Menu}</NavLink>
              </motion.li>
            ))}
  
          </ul>
        </nav>
        <nav className="block md:hidden">
          <motion.div
            initial={{y: -50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.2}}
          >
            <HiMenuAlt3 onClick={menuHandler} className="text-maincolor" size={30} />
          </motion.div>
          
          <div className={`fixed h-screen w-full  backdrop-blur-xl top-0 right-0 bottom-0 ring-0  bg-black/80 z-10 text-white duration-500 ease-in-out ${!isOpenMenu ? 'right-[-100%]' : ''}`}>
            
            <motion.div 
              initial={{rotate: 180}}
              whileInView={{rotate: 0}}
              transition={{delay: 0.3}}
              className='absolute right-4 top-4'>
              <FaTimes  onClick={menuHandler} size={30} />
            </motion.div>
            <ul className="flex flex-col gap-4 h-full items-center justify-center">
              {menuList.map((menuText, index) => (
                  <motion.li
                    initial={{x: 50, opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{delay: 0.1 * index}}
                    whileHover={{scale: 1.5}}
                    className="text-lg cursor-pointer" key={index}
                    onClick={() => {
                      setIsOpenMenu(false);
                      document.body.classList.remove('overflow-hidden');
                    }}
                  >
                    <NavLink  to={menuText.link}>
                      {menuText.Menu}
                    </NavLink>
                  </motion.li>
              ))}
            </ul>
          </div>
         
        </nav>
      </div>
    </div>
  )
}

export default Navbar
