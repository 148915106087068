import React from 'react'
import {AiOutlineLoading} from 'react-icons/ai'
//import Loadinglogo from '../assets/LoadingIcon.gif'

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      {/* <img
        src={Loadinglogo} // Replace with the actual path to your GIF file
        alt="Loading..."

      /> */}
      <div className="animate-[spin_0.5s_linear_infinite]">
        < AiOutlineLoading className="text-maincolor" size={30} />
      </div>
    </div>
  )
}

export default Loading
