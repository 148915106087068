import React from 'react'
import Hero from '../components/Header/Hero'
import AboutComponents from '../components/AboutComponents'
import CoureseComponents from '../components/CoureseComponents'
import ContactUsComponents from '../components/ContactUsComponents'
//import Slider from '../components/Header/Slider'
//import Parallax from '../components/Parallax'
//import transition from '../transition'

const Home = () => {
  return (
    <>
        <Hero />
        {/* <Slider /> */}
        <AboutComponents />
        <CoureseComponents />
        <ContactUsComponents />
  
    </>
  )
}

export default Home
