import React from 'react'

const GoogleMap = () => {
  return (
    <div>
      <div>
        <iframe 
            title="Acdemy"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d31267.097552708787!2d104.84388626807265!3d11.59572008827505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDM1JzQ0LjYiTiAxMDTCsDUxJzQwLjQiRQ!5e0!3m2!1sen!2skh!4v1696477467745!5m2!1sen!2skh" 
            width="100%" 
            height="450" 
            style={{border:0}} 
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
        </div>
    </div>
  )
}

export default GoogleMap
