import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import client from '../client';
import BlockContent from "@sanity/block-content-to-react";
import Loading from '../components/Loading';
//import transition from '../transition';

const PorjectId = process.env.REACT_APP_PROJECT_ID;
const DataSet = process.env.REACT_APP_DATA_SET;
const SingleCourses = () => {
  const [singlePost, setSinglePost] = useState(null);
  const {slug} = useParams();

  useEffect(() =>{
    client.fetch(`*[slug.current == "${slug}" ]{
      title,
      _id,
      slug,
      bio,
      content,
      image{
        asset->{
          _id,
          url
        },
        alt
      }
    }`).then((data) => setSinglePost(data[0]))
    .catch(console.error)
  }, [slug])
  //console.log(singlePost)
  if(!singlePost) return <Loading />
  return (
    <section className="py-20">
      <div className="container">
        <header>
            <div className="flex items-center gap-3">
              <img className="max-w-[100px]" src={singlePost.image.asset.url} alt={singlePost.image.alt} />
              <div>
                <h1 className="text-4xl mb-3 font-medium">{singlePost.title}</h1>
                <strong>{singlePost.bio}</strong>
              </div>
              
            </div>
        </header>
        <div className="prose md:prose-xl mx-auto">
          <BlockContent blocks={singlePost.content} projectId={PorjectId} dataset={DataSet} />
        </div>
      </div>
    </section>
  )
}

export default SingleCourses
