import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Layout from "./pages/Layout";
import ContactUs from "./pages/ContactUs";
import Courses from "./pages/Courses";
import PageNotFounds from "./pages/PageNotFounds";
import SingleCourses from "./pages/SingleCourses";
import { AnimatePresence } from "framer-motion";

const App = () => {
  const location = useLocation();
  return (
    <Layout>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route index path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/courese/:slug" element={<SingleCourses />} />
          <Route path="/courese" element={<Courses />} />
          <Route path="*" element={<PageNotFounds />} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
};

export default App;
