import {createClient } from '@sanity/client';

const PorjectId = process.env.REACT_APP_PROJECT_ID;
const DataSet = process.env.REACT_APP_DATA_SET;
// Initialize the Sanity client with your project settings
const client = createClient ({
  projectId: PorjectId,
  dataset: DataSet,
  apiVersion: new Date().toISOString().slice(0, 10),
  useCdn: false, // Set to true for production if desired
});

export default client;