import React, {useState, useEffect} from 'react'
import client from '../client'
import {Link} from 'react-router-dom'
import BlockContent from "@sanity/block-content-to-react";
import Loading from '../components/Loading';
import {motion} from 'framer-motion'
//import transition from '../transition';

const PorjectId = process.env.REACT_APP_PROJECT_ID;
const DataSet = process.env.REACT_APP_DATA_SET;

const Courses = () => {
  const [coures, setCoures] = useState(null);
  const [coursePage, setCouresPage] = useState(null);

  useEffect(() => {
    client.fetch(`*[_type == 'courese']{
      title,
      slug,
      bio,
      image{
        asset->{
          url,
          _id
        }
      }
    }`).then((data) => setCoures(data))
    .catch(console.error)
  }, [])

  useEffect(() =>{
    client.fetch(`*[_type == "page" && slug.current == "coures"][0]{
      title,
      bio,
      content,
    }`).then((data) => setCouresPage(data))
    .catch(console.error)
  },[])
  if(!coures && !coursePage) return <Loading />
  return (
    <div className='grid py-20'>
       <div className="container">

          <motion.h1
            initial={{y: -10, opacity: 0}}
            animate={{y:0 , opacity: 1}}
            transition={{delay: 0.2, duration: 0.5}}
            className="my-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {coursePage?.title}
          </motion.h1>

          {/* Bio */}
          <motion.strong
           className="text-maincolor block pb-4"
           initial={{y: -10, opacity: 0}}
           animate={{y:0 , opacity: 1}}
           transition={{delay: 0.4, duration: 0.5}}
           >
            {coursePage?.bio}
          </motion.strong>

          {/* Gallery */}
          <div className="grid gap-4 text-white lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            {coures?.map((couresItem, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 block p-4 rounded-lg" 
                initial={{x: -100, opacity: 0}}
                whileInView={{x: 0, opacity: 1}}
                viewport={{once: true}}
                transition={{delay: 0.1 * index, duration: 0.5}}
              >
                  <Link to={`/courese/${couresItem.slug.current}`} key={index}>
                    <div>
                        <h2>{couresItem.title}</h2>
                        <strong>{couresItem.bio}</strong>
                        <img className="w-[100px] ml-auto" src={couresItem.image.asset.url} alt={coures.title} />
                    </div>
                </Link>
              </motion.div>
            ))}
          </div>
          
          {/* content */}
          <div className="prose lg:prose-xl mx-auto">
            <BlockContent blocks={coursePage?.content} projectId={PorjectId} dataset={DataSet} />
          </div>


      </div>
    </div>
  )
}

export default Courses
