import ContactForm from "./ContactForm"
import React from 'react'
import GoogleMap from "./GoogleMap"

const ContactUsComponents = () => {
   
  return (
    <section className="py-20 ">
      <div className="container">
        <h2 className="text-4xl font-bold mb-4 ">Contact Us</h2>
        <div className=" grid md:grid-cols-2 grid-cols-1 gap-4">
          <ContactForm />
          <GoogleMap />
        </div>
      </div>
    </section>
  )
}

export default ContactUsComponents
