import React, {useState, useEffect} from 'react';
import client from '../client';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import { motion } from 'framer-motion';

const CoureseComponents = () => {
    const [coureses, setCourese] = useState(null)
    useEffect(() => {
        client
        .fetch(`*[_type == "courese"] | order(publishedAt desc) [0..5] {
            title,
            slug,
            bio,
            image{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`)
        .then((data) => setCourese(data))
        .catch(console.error)
    }, [])
    if(!coureses) return <Loading />
    return ( 
    <section className="py-20  text-white">
        <div className="container">
            <motion.h2
                className="text-4xl text-black font-bold mb-4"
                initial={{y: -20, opacity: 0}}
                whileInView={{y: 0, opacity: 1}}
                transition={{delay: 0.5}}
                viewport={{once: true}}
            >
                Education
            </motion.h2>

            <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                {coureses?.map((courese, index) => (
                <motion.div
                    key={index}  
                    className="bg-gray-800 duration-500 ease-in-out block hover:shadow-xl shadow-lg hover:shadow-maincolor rounded-xl p-4"
                    initial={{scale: 0, opacity: 0}}
                    whileInView={{scale: 1, opacity: 1}}
                    transition={{delay: 0.1 * index}}
                    viewport={{once: true}}
                >
                     <Link
                        to={`/courese/${courese.slug.current}`} 
                    >
                        <div >
                            <h3 className="text-2xl font-bold">{courese.title}</h3>
                            <p>{courese.bio}</p>
                            <img className="w-[100px] my-4 ml-auto" src={courese.image.asset.url} alt={courese.image.alt} />
                        </div>
                    </Link>
                </motion.div>
                ))}
            </div>
        </div>
    </section>
    )
}

export default CoureseComponents
