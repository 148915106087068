import React, {useState, useEffect} from 'react'
import Button from './SmallComponents/Button'
import { Link } from 'react-router-dom'
import client from '../client'
//import BlockContent from "@sanity/block-content-to-react";
import {motion} from 'framer-motion'
import Loading from './Loading'

const AboutComponents = () => {
  
  const [aboutUs, setAboutUs] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`*[_type == "page" && slug.current == "about-us"][0]{
          title,
          _id,
          metaDescription,
          bio,
          image{
            asset->{
              _id,
              url
            },
            alt
          },
          gallery[]{
            'url': asset->url
          }
        }`);
        setAboutUs(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(); // Call the async function
  }, []);
  
  // useEffect(() => {
  //   client
  //   .fetch(`*[_type == "page" && slug.current == "about-us"][0]{
  //     title,
  //     _id,
  //     metaDescription,
  //     bio,
  //     image{
  //       asset->{
  //         _id,
  //         url
  //       },
  //       alt
  //     },
  //     gallery[]{
  //       'url': asset->url
  //     }
  //   }`).then((data) => setAboutUs(data))
  //   .catch(console.error)
  // }, [])
  //console.log(aboutUs)

  if(!aboutUs) return <Loading />

  return (
    <div className="container">
      <div className="py-28 grid lg:grid-cols-3 gap-4">
        <div className="columns-2 py-4 lg:col-span-2  order-2 md:order-1" >
           {aboutUs.gallery.map((image, index) => (
              <motion.img 
                key={index} src={image.url} alt="" 
                className="mb-4"
                initial={{x: -100, opacity: 0}}
                whileInView={{x: 0, opacity: 1}}
                transition={{delay: 0.1 * index}}
                viewport ={{once: true}}
                />
            ))}
        </div>
        <motion.div
             initial={{x: 100, opacity: 0}} 
             whileInView={{x: 0, opacity: 1}}
             transition={{delay: 0.7}}
             viewport ={{once: true}}
            className="md:sticky top-20 h-fit grid gap-4 order-1 md:order-2">
            <h2>{aboutUs.title}</h2>
            <strong className="text-4xl">{aboutUs.bio}</strong>
            <p>{aboutUs.metaDescription}</p>
            <Link to="/about-us" >
              <Button text="Read More" />
            </Link>
        </motion.div>
      </div>
    </div>
  )
}

export default AboutComponents
