import React, {useState} from 'react'

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('https://script.google.com/macros/s/AKfycbxTU56i7I7D12itOdAniSz2XoNRnkmDKab5SKKV0ZBCFIUMXMLiMH_5iz6on2pDxBnE/exec', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            alert('Form submitted successfully!');
          } else {
            alert('Form submission failed.');
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

  return (
    <div>
        <div className="rounded-2xl">
            <form className="flex flex-col gap-3" onSubmit={handleSubmit} >
                <input
                    className="rounded-md p-2"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    className="rounded-md p-2"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <input
                    className="rounded-md p-2"
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                />
                <textarea
                    className="rounded-md p-2"
                    id="message"
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                <button className="bg-maincolor shadow-lg text-white w-[100px] rounded-lg px-4 py-2" type="submit">Sent</button>
            </form>
        </div>
    </div>
  )
}

export default ContactForm
