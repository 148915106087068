import React, {useState, useEffect} from 'react';
import client from '../client';
import BlockContent from "@sanity/block-content-to-react";
import Loading from '../components/Loading';
import { motion } from 'framer-motion';
//import transition from '../transition';

const PorjectId = process.env.REACT_APP_PROJECT_ID;
const DataSet = process.env.REACT_APP_DATA_SET;


const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState(null)
  useEffect(() => {
    client
    .fetch(`*[_type == "page" && slug.current == "about-us"][0]{
      title,
      _id,
      content,
      bio,
      image{
        asset->{
          _id,
          url
        },
        alt
      },
      gallery[]{
        'url': asset->url
      }
    }`).then((data) => setAboutUs(data))
    .catch(console.error)
  }, [])
  //console.log(aboutUs)
  if(!aboutUs) return <Loading />
  return (
    <div className=''>
      
      <div className="relative">
        <img className="w-full max-h-[500px] object-cover"  src={aboutUs.image.asset.url} alt={aboutUs.image.alt} />
        <div className="absolute bottom-0 container py-5 left-0 right-0 text-black">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl">{aboutUs.title}</h1>
          <p>{aboutUs.bio}</p>
        </div> 
      </div>
      
      {/* <img src={urlFor(aboutUs.image).url()} alt={aboutUs.image.alt} /> */}
      <div className="container">
        <div className="prose lg:prose-xl mx-auto">
          <BlockContent blocks={aboutUs.content} projectId={PorjectId} dataset={DataSet} />
        </div>
      </div>

      <div className="py-5 mg:px-0 md:px-0 px-4">
        <div className="lg:columns-3 columns-2">
          {aboutUs.gallery.map((image, index) => (
            <motion.img
              initial={{x: -100, opacity: 0}}
              whileInView={{x: 0, opacity: 1}}
              transition={{delay: 0.1 * index}}
              viewport={{once: true}}
              className="mb-4" 
              key={index}
              src={image.url}
              alt=""
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AboutUs
