import React,{useState, useEffect} from 'react'
import Button from '../SmallComponents/Button'
import { Navigation, Pagination, Autoplay, EffectCards, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
//import Loading from '../Loading';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';
import {motion} from 'framer-motion'

import client from '../../client'

//const imageList = [Image1e,  Image4, Image5, Image3]
const Hero = () => {

  const [home, setHome] = useState(null);

  useEffect(() =>{
    client.fetch(`*[_type == 'page' && slug.current == 'home'][0]{
      title,
      bio,
      metaDescription,
      gallery[]{
        'url': asset->url
      }
    }`).then((data) => setHome(data))
    .catch(console.error)
  }, [])

  //if(!home) return <Loading />
  return (
    <SkeletonTheme baseColor="#cccccc" highlightColor="#fafafa">
    <div className="md:h-[calc(100vh-100px)] md:py-0 py-24  items-center grid">
      <div className="container grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3 items-center">
        <div className="max-w-[500px] grid gap-3 items-center">
          <motion.h1 
            initial={{y: 20, opacity: 0}} 
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.5}}
            className="text-5xl font-bold"
            >
           {home?.bio || <Skeleton count={3} />} 
          </motion.h1>
          <motion.p
            initial={{y: 20, opacity: 0}} 
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.7}}
          >
            {home?.metaDescription || <Skeleton count={2} />}
          </motion.p>
          <motion.div
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.9}}
          >
              <Button text={`Read More`} />
          </motion.div>
        </div>

        <div className="col-span-1 relative max-h-[650px] rounded-lg">
          <div>
          <Swiper
                style={{
                  '--swiper-navigation-color': 'red',
                  '--swiper-pagination-color': 'red',
                }}
                className="lg:w-full w-[80%]"
                modules={[Navigation, Pagination, Autoplay, EffectCards, A11y]}
                //loop={true}
                spaceBetween={50}
                slidesPerView={1}
                // scrollbar={{ draggable: true }}
                effect={'cards'}
                cardsEffect= {{
                  prev: {
                    shadow: true,
                    translate: [0, 0, -100],
                  },
                  next: {
                    translate: ['100%', 0, 0],
                  },
                  perSlideRotate: 1,
                }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                navigation
                pagination={{
                  dynamicBullets: true,
                }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
              {home?.gallery.map((image, value) => (
                  <SwiperSlide key={value} >
                     { <img
                        className="rounded-lg" 
                        key={value} 
                        src={image.url} 
                        alt={`image_${value + 1}`} /> || <Skeleton height={100} /> }
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
    </SkeletonTheme>
  )
}

export default Hero
