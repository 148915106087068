import React, {useState, useEffect} from 'react'
import client from '../client';
import Loading from './Loading';

const Logo = () => {
    const [logo, setLogo] = useState('');
    useEffect(() =>{
      client.fetch(`*[_type == 'page' && slug.current == 'home'][0]{
        image{
          asset->{
            url,
            _id
          }
        }
      }`).then((data) => setLogo(data))
      .catch(console.error)
    }, [])
   if(!logo) return <Loading />
   const logoImage = logo.image.asset.url;
    return <img src={logoImage} alt="Company Logo" />;
}

export default Logo
